import { useCallback, useContext, useEffect, useState } from 'react';
import {
  AuthenticatedSessionContext,
  useAuthenticatedSession,
} from '../authenticatedSession/AuthenticatedSessionContext';
import {
  ContactWithRoleDocument,
  EngagementLetterDocument,
  ExpenseEntryData,
  ExpenseEntryDocument,
  FinancialInstitutionDocument,
  FirmDocument,
  HumanDocument,
  InvoiceData,
  InvoiceDocument,
  KnowledgeBasePageDocument,
  MatterData,
  MatterDocument,
  MatterDocumentDocument,
  NoteData,
  NoteDocument,
  OrganizationDocument,
  TaskData,
  TaskDocument,
  TimeEntryData,
  TimeEntryDocument,
  TransactionData,
  TransactionDocument,
  UserData,
  UserDocument,
} from './baseTypes';
import { OrderByClause, WhereClause } from './queryBuilder';
import { FlattenedMatter } from './aggregateTypes';

export function useExpenseEntries(
  matterId: string | undefined,
  orderBy?: OrderByClause<ExpenseEntryData>
) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [entries, setEntries] = useState<ExpenseEntryDocument[]>();

  useEffect(() => {
    setEntries(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToExpenseEntriesChanges(
      matterId ? [['matter', '==', matterId]] : [],
      orderBy ? [orderBy] : [],
      (collectionSnapshot) => {
        const items = collectionSnapshot.docs.map((docSnapshot) => {
          return { ...docSnapshot.data(), id: docSnapshot.id };
        });
        setEntries(items);
      }
    );
  }, [matterId]);

  return entries;
}

export function useExpenseEntryAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: ExpenseEntryData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addExpenseEntry(data);
  }, []);
}

export function useFinancialInstitutions() {
  const authSession = useContext(AuthenticatedSessionContext);
  const [institutions, setInstitutions] = useState<
    FinancialInstitutionDocument[] | undefined
  >();
  useEffect(() => {
    setInstitutions(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToFinancialInstitutionsChanges(
      (snapshot) => {
        setInstitutions(
          snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
      },
      [],
      []
    );
  }, [authSession]);

  return institutions;
}

export function useKnowledgeBasePages() {
  const authSession = useContext(AuthenticatedSessionContext);
  const [pages, setPages] = useState<KnowledgeBasePageDocument[] | undefined>();
  useEffect(() => {
    setPages(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToKnowledgeBasePagesChanges(
      (snapshot) => {
        setPages(
          snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
      },
      [],
      [['createdTimestamp', 'asc']]
    );
  }, [authSession]);

  return pages;
}

export function useMatters(options?: {
  where?: WhereClause<MatterData>[];
  orderBy?: OrderByClause<MatterData>[];
}) {
  const [matters, setMatters] = useState<MatterDocument[]>();
  const authSession = useContext(AuthenticatedSessionContext);
  useEffect(() => {
    setMatters(undefined);
    if (!authSession) {
      return;
    }
    const where = options?.where ? [...options.where] : [];
    const orderBy = options?.orderBy ? [...options.orderBy] : [];
    if (orderBy.length === 0) {
      orderBy.push(['name', 'asc']);
    }
    const unsubscribe = authSession.db.subscribeToMattersChanges(
      (collectionSnapshot) => {
        setMatters(
          collectionSnapshot.docs.map((docSnapshot) => {
            const data = docSnapshot.data();
            return { ...data, id: docSnapshot.id };
          })
        );
      },
      where,
      orderBy
    );
    return () => {
      unsubscribe();
    };
  }, [authSession, JSON.stringify(options)]);

  return matters;
}

export function useEngagementLetters(matterId: string) {
  const authSession = useAuthenticatedSession();
  const [engagementLetters, setEngagementLetters] =
    useState<EngagementLetterDocument[]>();
  useEffect(() => {
    setEngagementLetters(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToEngagementLettersChanges(
      [['matterId', '==', matterId]],
      [],
      (snapshot) => {
        setEngagementLetters(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    );
  }, [authSession, matterId]);
  return engagementLetters;
}

export function useFlattenedMatter(matterId: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [flattenedMatter, setFlattenedMatter] = useState<FlattenedMatter>();
  const [snapshotTimestamp, setSnapshotTimestamp] = useState<Date>();
  const setLatestFlattenedMatter = (data: FlattenedMatter | undefined) => {
    setFlattenedMatter(data);
    setSnapshotTimestamp(new Date());
  };
  const { matter, snapshotTimestamp: matterSnapshotTimestamp } =
    useMatter(matterId);
  const { firm, snapshotTimestamp: firmSnapshotTimestamp } = useFirm();
  const groupedContacts = useMatterHumansAndOrganizations(matterId);
  const {
    user: responsibleAttorney,
    snapshotTimestamp: responsibleAttorneySnapshotTimestamp,
  } = useUser(matter?.responsibleAttorney);
  const {
    user: engagementOriginator,
    snapshotTimestamp: engagementOriginatorSnapshotTimestamp,
  } = useUser(matter?.engagementOriginator);
  const {
    user: referralOriginator,
    snapshotTimestamp: referralOriginatorSnapshotTimestamp,
  } = useUser(matter?.referralOriginator);
  const { primaryContact, snapshotTimestamp: primaryContactSnapshotTimestamp } =
    usePrimaryMatterContact(matterId);
  const engagementLetters = useEngagementLetters(matterId);
  useEffect(() => {
    setLatestFlattenedMatter(undefined);
    if (!authSession || !matter || !firm || !groupedContacts) {
      return;
    }
    const flattenedMatter = authSession.db.makeFlattenedMatterFromData({
      matter,
      firm,
      groupedContacts,
      responsibleAttorney,
      engagementOriginator,
      referralOriginator,
      primaryContact,
      engagementLetters,
    });
    setLatestFlattenedMatter(flattenedMatter);
  }, [
    authSession,
    matterSnapshotTimestamp,
    firmSnapshotTimestamp,
    groupedContacts.snapshotTimestamp,
    responsibleAttorneySnapshotTimestamp,
    engagementOriginatorSnapshotTimestamp,
    referralOriginatorSnapshotTimestamp,
    primaryContactSnapshotTimestamp,
    engagementLetters,
  ]);
  return { flattenedMatter, snapshotTimestamp };
}

export const useMatterHumansAndOrganizations = (matterId: string) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const {
    contactsWithRoles,
    snapshotTimestamp: contactsWithRolesSnapshotTimestamp,
  } = useMatterContactsWithRoles(matterId);
  const [humansByDocumentId, setHumansByDocumentId] =
    useState<Record<string, HumanDocument>>();
  const [organizationsByDocumentId, setOrganizationsByDocumentId] =
    useState<Record<string, OrganizationDocument>>();
  const [snapshotTimestamp, setSnapshotTimestamp] = useState<Date>();
  const setLatestHumansByDocumentId = (
    fn: (
      prev: Record<string, HumanDocument> | undefined
    ) => Record<string, HumanDocument> | undefined
  ) => {
    setHumansByDocumentId(fn);
    setSnapshotTimestamp(new Date());
  };
  const setLatestOrganizationsByDocumentId = (
    fn: (
      prev: Record<string, OrganizationDocument> | undefined
    ) => Record<string, OrganizationDocument> | undefined
  ) => {
    setOrganizationsByDocumentId(fn);
    setSnapshotTimestamp(new Date());
  };
  useEffect(() => {
    setLatestHumansByDocumentId(() => undefined);
    setLatestOrganizationsByDocumentId(() => undefined);
    if (!authSession || !contactsWithRoles) {
      return;
    }
    const unsubscribes: (() => void)[] = [];

    for (const contactWithRole of contactsWithRoles) {
      if (contactWithRole.contact.parent.id === 'humans') {
        unsubscribes.push(
          authSession.db.subscribeToHumanChanges(
            contactWithRole.contact.id,
            (snapshot) => {
              setLatestHumansByDocumentId((prev) => ({
                ...(prev ?? {}),
                [contactWithRole.contact.id]: {
                  ...snapshot.data(),
                  id: snapshot.id,
                } as HumanDocument,
              }));
            }
          )
        );
      } else if (contactWithRole.contact.parent.id === 'organizations') {
        unsubscribes.push(
          authSession.db.subscribeToOrganizationChanges(
            contactWithRole.contact.id,
            (snapshot) => {
              setLatestOrganizationsByDocumentId((prev) => ({
                ...(prev ?? {}),
                [contactWithRole.contact.id]: {
                  ...snapshot.data(),
                  id: snapshot.id,
                } as OrganizationDocument,
              }));
            }
          )
        );
      }
    }
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [, authSession, contactsWithRolesSnapshotTimestamp]);
  return {
    humans: humansByDocumentId ? Object.values(humansByDocumentId) : [],
    organizations: organizationsByDocumentId
      ? Object.values(organizationsByDocumentId)
      : [],
    snapshotTimestamp,
  };
};

export function useMatterContactsWithRoles(matterId: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [contactsWithRoles, setContactsWithRoles] =
    useState<ContactWithRoleDocument[]>();
  const [snapshotTimestamp, setSnapshotTimestamp] = useState<Date>();
  const setLatestContactsWithRoles = (
    data: ContactWithRoleDocument[] | undefined
  ) => {
    setContactsWithRoles(data);
    setSnapshotTimestamp(new Date());
  };
  useEffect(() => {
    setLatestContactsWithRoles(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToMatterContactsWithRolesChanges(
      matterId,
      (snapshot) => {
        setLatestContactsWithRoles(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    );
  }, [matterId, authSession]);
  return { contactsWithRoles, snapshotTimestamp };
}

export function useMatter(id: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [matter, setMatter] = useState<MatterDocument | undefined>();
  const [snapshotTimestamp, setSnapshotTimestamp] = useState<Date>();
  useEffect(() => {
    setMatter(undefined);
    setSnapshotTimestamp(new Date());
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToMatterChanges(id, (snapshot) => {
      setMatter(undefined);
      setSnapshotTimestamp(new Date());
      const data = snapshot.data();
      if (!data) {
        return;
      }
      setMatter({ ...data, id: snapshot.id });
      setSnapshotTimestamp(new Date());
    });
  }, [authSession, id]);
  return { matter, snapshotTimestamp };
}

export function usePrimaryMatterContact(id: string) {
  const primaryContactWithRoles = usePrimaryMatterContactWithRoles(id);
  const [primaryContact, setPrimaryContact] = useState<HumanDocument>();
  const [snapshotTimestamp, setSnapshotTimestamp] = useState<Date>();
  const setLatestPrimaryContact = (data: HumanDocument | undefined) => {
    setPrimaryContact(data);
    setSnapshotTimestamp(new Date());
  };
  useEffect(() => {
    setLatestPrimaryContact(undefined);
    if (!primaryContactWithRoles) {
      return;
    }
    if (!primaryContactWithRoles.contact.id) {
      return;
    }
    return primaryContactWithRoles.contact.onSnapshot((snapshot) => {
      setLatestPrimaryContact(undefined);
      const data = snapshot.data();
      if (!data) {
        return;
      }
      // Primary contact is always a human.
      setLatestPrimaryContact({ ...data, id: snapshot.id } as HumanDocument);
    });
  }, [primaryContactWithRoles?.contact.id]);
  return { primaryContact, snapshotTimestamp };
}

export function usePrimaryMatterContactWithRoles(id: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const { matter } = useMatter(id);
  const [primaryContact, setPrimaryContact] = useState<
    ContactWithRoleDocument | undefined
  >();
  useEffect(() => {
    setPrimaryContact(undefined);
    if (!authSession) {
      return;
    }
    if (!matter?.primaryContact) {
      return;
    }
    return matter.primaryContact.onSnapshot((snapshot) => {
      setPrimaryContact(undefined);
      const data = snapshot.data();
      if (!data) {
        return;
      }
      setPrimaryContact({ ...data, id: snapshot.id });
    });
  }, [authSession, id, matter?.primaryContact?.id]);
  return primaryContact;
}

export function useMatterNotes(matterId: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [notes, setNotes] = useState<NoteDocument[]>();
  useEffect(() => {
    setNotes(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToMatterNotesChanges(
      matterId,
      [['createdTimestamp', 'desc']],
      (collectionSnapshot) => {
        const notes = collectionSnapshot.docs
          .map((docSnapshot) => {
            const data = docSnapshot.data();
            if (!data) {
              return null;
            }
            return { ...data, id: docSnapshot.id };
          })
          .filter((note) => !!note) as NoteDocument[];

        setNotes(notes);
      }
    );
  }, [matterId]);
  return notes;
}

export function useMatterNoteAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return async (matterId: string, data: NoteData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addMatterNote(matterId, data);
  };
}

export function useMatterDocuments(matterId: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [items, setItems] = useState<MatterDocumentDocument[]>();
  useEffect(() => {
    setItems(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToMatterDocumentsChanges(
      matterId,
      [['createdTimestamp', 'desc']],
      (collectionSnapshot) => {
        const documents = collectionSnapshot.docs
          .map((docSnapshot) => {
            const data = docSnapshot.data();
            if (!data) {
              return null;
            }
            return { ...data, id: docSnapshot.id };
          })
          .filter((doc) => !!doc) as MatterDocumentDocument[];

        setItems(documents);
      }
    );
  }, [matterId]);
  return items;
}

export function useUsers(
  where: WhereClause<UserData>[] = [],
  orderBy: OrderByClause<UserData>[] = []
) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [users, setUsers] = useState<UserDocument[]>();
  useEffect(() => {
    setUsers(undefined);
    if (!authSession) {
      console.log(
        'Waiting for auth session before subscribing to users changes'
      );
      return;
    }
    console.log('Subscribing to users changes');
    return authSession.db.subscribeToUsersChanges(
      (collectionSnapshot) => {
        console.log(`Users changed (${collectionSnapshot.docs.length} found)`);
        setUsers(
          collectionSnapshot.docs.map((docSnapshot) => ({
            ...docSnapshot.data(),
            id: docSnapshot.id,
          }))
        );
      },
      where,
      orderBy
    );
  }, [JSON.stringify(where), JSON.stringify(orderBy), authSession]);
  return users;
}

export function useUser(id: string | undefined) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [user, setUser] = useState<UserDocument | undefined>();
  const [snapshotTimestamp, setSnapshotTimestamp] = useState<Date>();
  const setLatestUser = (data: UserDocument | undefined) => {
    setUser(data);
    setSnapshotTimestamp(new Date());
  };
  useEffect(() => {
    setLatestUser(undefined);
    if (!authSession) {
      return;
    }
    if (!id) {
      return;
    }
    return authSession.db.subscribeToUserChanges(id, (snapshot) => {
      setLatestUser({ ...snapshot.data(), id: snapshot.id } as UserDocument);
    });
  }, [id, authSession]);
  return { user, snapshotTimestamp };
}

export function useCurrentUser() {
  const authSession = useContext(AuthenticatedSessionContext);
  const { user, snapshotTimestamp } = useUser(authSession?.userId);
  return { currentUser: user, snapshotTimestamp };
}

export function useFirm() {
  const authSession = useContext(AuthenticatedSessionContext);
  const [firm, setFirm] = useState<FirmDocument | undefined>();
  const [snapshotTimestamp, setSnapshotTimestamp] = useState<Date>();
  const setLatestFirm = (data: FirmDocument | undefined) => {
    setFirm(data);
    setSnapshotTimestamp(new Date());
  };
  useEffect(() => {
    setLatestFirm(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToFirmChanges((snapshot) => {
      setLatestFirm({ ...snapshot.data(), id: snapshot.id } as FirmDocument);
    });
  }, [authSession]);

  return { firm, snapshotTimestamp };
}

export function useTasks(
  matterId: string | undefined,
  orderBy: OrderByClause<TaskData>[] = []
) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [tasks, setTasks] = useState<TaskDocument[]>();
  useEffect(() => {
    setTasks(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToTasksChanges(
      (collectionSnapshot) => {
        setTasks(
          collectionSnapshot.docs.map((docSnapshot) => ({
            ...docSnapshot.data(),
            id: docSnapshot.id,
          }))
        );
      },
      matterId ? [['matter', '==', matterId]] : [],
      orderBy
    );
  }, [matterId, JSON.stringify(orderBy), authSession]);
  return tasks;
}

export function useTaskAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: TaskData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addTask(data);
  }, []);
}

export function useTimeEntries(matterId: string | undefined) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [timeEntries, setTimeEntries] = useState<TimeEntryDocument[]>();
  useEffect(() => {
    setTimeEntries(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToTimeEntriesChanges(
      matterId ? [['matter', '==', matterId]] : [],
      [],
      (collectionSnapshot) => {
        setTimeEntries(
          collectionSnapshot.docs
            .map((docSnapshot) => {
              return { ...docSnapshot.data(), id: docSnapshot.id };
            })
            .sort((a, b) => (a.earnedYmd.isOnOrAfter(b.earnedYmd) ? -1 : 1))
        );
      }
    );
  }, [matterId, authSession]);

  return timeEntries;
}

export const useInvoices = (where: WhereClause<InvoiceData>[] = []) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [invoices, setInvoices] = useState<InvoiceDocument[]>();
  useEffect(() => {
    setInvoices(undefined);
    if (!authSession) {
      return;
    }
    const unsubscribe = authSession.db.subscribeToInvoicesChanges(
      where,
      (snapshot) => {
        setInvoices(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    );
    return () => {
      unsubscribe();
    };
  }, [authSession, JSON.stringify(where)]);
  return invoices;
};

export const useTransactions = (where: WhereClause<TransactionData>[] = []) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const [transactions, setTransactions] = useState<TransactionDocument[]>();
  useEffect(() => {
    setTransactions(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToTransactionsChanges(where, (snapshot) => {
      setTransactions(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
  }, [JSON.stringify(where), authSession]);
  return transactions;
};

export function useTimeEntryAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: TimeEntryData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addTimeEntry(data);
  }, []);
}

export function useTransactionAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: TransactionData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addTransaction(data);
  }, []);
}
