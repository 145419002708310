import { Suspense, lazy, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Callback from './Callback';
import { AuthenticatedSessionContext } from './authenticatedSession/AuthenticatedSessionContext';
import { LoadingView } from './components/LoadingView';
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
const Flywheel = lazy(() => import('./views/Matters/Flywheel/Flywheel'));
const Matters = lazy(() => import('./views/Matters/Matters'));
const Fees = lazy(() => import('./views/Fees'));
const Expenses = lazy(() => import('./views/Expenses'));
const Tasks = lazy(() => import('./views/Tasks/Tasks'));
const BizDev = lazy(() => import('./views/BizDev'));
const Invoices = lazy(() => import('./views/Invoices/Invoices'));
const OpsHR = lazy(() => import('./views/Financials/OpsHR'));
const Resources = lazy(() => import('./views/KnowledgeBase/KnowledgeBase'));
const Wellness = lazy(() => import('./views/Wellness'));
const Settings = lazy(() => import('./views/Settings/Settings'));
const MatterDashboard = lazy(
  () => import('./views/MatterDashboard/MatterDashboard')
);
const ClaimsSpreadsheet = lazy(
  () => import('./views/ClaimsSpreadsheet/ClaimsSpreadsheet')
);
const MattersSpreadsheet = lazy(
  () => import('./views/Matters/MattersSpreadsheet/MattersSpreadsheet')
);
const ClioSync = lazy(() => import('./components/ClioSync/ClioSync'));

const AppRoutes = () => {
  const authSession = useContext(AuthenticatedSessionContext);

  return (
    <Suspense fallback={<LoadingView />}>
      {authSession ? (
        <Switch>
          <Route path='/dashboard/:modal'>
            <Dashboard />
          </Route>
          <Route path='/dashboard'>
            <Dashboard />
          </Route>
          <Route path='/flywheel'>
            <Flywheel />
          </Route>
          <Route path='/matters/:tab'>
            <Matters />
          </Route>
          <Route path='/matters'>
            <Matters />
          </Route>
          <Route path='/fees'>
            <Fees />
          </Route>
          <Route path='/expenses'>
            <Expenses />
          </Route>
          <Route path='/tasks'>
            <Tasks />
          </Route>
          <Route path='/biz-dev'>
            <BizDev />
          </Route>
          <Route path='/invoices'>
            <Invoices />
          </Route>
          <Route path='/ops-hr'>
            <OpsHR />
          </Route>
          <Route path='/resources'>
            <Resources />
          </Route>
          <Route path='/wellness'>
            <Wellness />
          </Route>
          <Route path='/settings'>
            <Settings />
          </Route>
          <Route path='/matter/:id'>
            <MatterDashboard />
          </Route>
          <Route path='/spreadsheet'>
            <MattersSpreadsheet />
          </Route>
          <Route path='/claims'>
            <ClaimsSpreadsheet />
          </Route>
          <Route exact path='/callback'>
            <Callback />
          </Route>
          <Route path='/clio-sync'>
            <ClioSync />
          </Route>
        </Switch>
      ) : (
        <Redirect to='/login' />
      )}
    </Suspense>
  );
};

export default AppRoutes;
