import React, { useEffect, useState } from 'react';
import './LandingPage.css';
import HeaderBar from './HeaderBar';
import HeroSection from './HeroSection';
import BodySection from './BodySection';
import Footer from './Footer';
import section2Image from '../../assets/landing-page2.webp';
import section3Image from '../../assets/landing-page3.webp';
import section4Image from '../../assets/landing-page4.webp';
import section5Image from '../../assets/landing-page5.webp';
import LandingGradientBackground from '../LandingGradientBackground/LandingGradientBackground';

const LandingPage = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  useEffect(() => {
    const sections = document.querySelectorAll('.body-section');
    const images = document.querySelectorAll('.body-image');
    const fixedImageContainer = document.createElement('div');
    fixedImageContainer.className = 'fixed-image-container';
    document.body.appendChild(fixedImageContainer);
    let currentFixedImageSrc = '';
    let lastKnownScrollPosition = 0;
    let ticking = false;

    const handleScroll = () => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          let foundActiveSection = false;

          sections.forEach((section, index) => {
            const img = images[index];
            const rect = section.getBoundingClientRect();
            const isEnteringTop = rect.top <= 288 && rect.bottom >= 300;

            // Manage the fixed image display as before
            if (isEnteringTop && !foundActiveSection) {
              foundActiveSection = true;
              if (currentFixedImageSrc !== img.src) {
                fixedImageContainer.style.backgroundImage = `url("${img.src}")`;
                fixedImageContainer.style.display = 'block';
                fixedImageContainer.style.top = '0px'; // Fix at the top when reached
                currentFixedImageSrc = img.src;
              }
              // Add the visible class when the section is entering the top area
              img.classList.add('visible');
            } else {
              // Optionally remove the visible class if not in the viewing area
              img.classList.remove('visible');
            }
          });

          if (!foundActiveSection) {
            fixedImageContainer.style.display = 'none'; // Hide if no active section
            currentFixedImageSrc = '';
          }

          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.removeChild(fixedImageContainer);
    };
  }, []);

  return (
    <div className='landing-page landing-page-modal'>
      <LandingGradientBackground>
        <HeaderBar toggleModal={toggleModal} />
        <HeroSection toggleModal={toggleModal} />
      </LandingGradientBackground>
      <div className='body-sections-container'>
        <BodySection
          title='Seamless Workflow'
          subtitle='Quick & Easy Intake'
          paragraph='We started Practist to simplify and improve our own firm.
            Practist takes what was an 11-step intake process and boils it down to 3 intuitive steps.<br /><br />
            No more exporting or converting between systems. Practist manages the client lifecycle from first call through closing the engagement, and beyond.'
          image={section2Image}
          className='body-image'
        />
        <BodySection
          title='AI-Powered Sourcing and Intake'
          subtitle='New Clients Straight to Your Practist Dashboard'
          paragraph='Practist is the first law practicement management program to source leads for you, qualify them, and put them in your Practist dashboard.<br /><br />Once a potential client is in your dashboard, you can quickly accept, reject, or otherwise follow up with the potential client. All clients are vetted for case type, location, and budget.'
          image={section3Image}
          className='body-image'
        />
        <BodySection
          title='Effortless time entry'
          subtitle='Enter time as quickly as jotting it down on a pad of paper.'
          paragraph='It is now as easy to enter time, tasks, and expenses into your practice management system as it is to scribble on that notepad sitting on your desk.'
          image={section4Image}
          className='body-image'
        />
        <BodySection
          title='Firm resources and announcements'
          subtitle='Communicate with your team more easily than ever before.'
          paragraph="Practist is about more than just practice management - it's about firm management. Now you can share resources, announcements and policies with your firm, all in one place"
          image={section5Image}
          className='body-image'
        />
        <Footer toggleModal={toggleModal} />
        {showModal && (
          <div className='modal'>
            <div className='modal-content'>
              <span className='close' onClick={() => setShowModal(false)}>
                &times;
              </span>
              <iframe
                src='https://docs.google.com/forms/d/e/1FAIpQLScBDk1Vz3sYHn4l-SiLifS6oPL2dxqG-3RmZB9D_NvYRUs_Og/viewform?usp=pp_url&entry.1260304866=I+consent+to+receive+product+updates/announcements+from+Practist.'
                style={{ width: '100%', height: '520px' }} // Use style attribute for direct inline styles
                frameborder='0'
                marginheight='0'
                marginwidth='0'
              >
                Loading…
              </iframe>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
