import * as Sentry from '@sentry/react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { logEvent } from 'firebase/analytics';
import { onAuthStateChanged } from 'firebase/auth';
import { lazy, Suspense, useContext, useEffect, useRef, useState } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticatedSessionContext } from './authenticatedSession/AuthenticatedSessionContext';
import { AuthenticatedSession } from './authenticatedSession/authenticatedSession';
import SignEngagementLetter from './views/SignEngagementLetter/SignEngagementLetter';
import FirebaseAnalytics from './components/FirebaseAnalytics';
import LandingPage from './components/LandingPage/LandingPage';
import SidebarMenu from './components/SidebarMenu/SidebarMenu';
import Header from './components/SiteHeader/SiteHeader';
import { FirmDatabase } from './database/firmDatabase';
import { analytics, auth, db } from './firebaseConfig';
import { attemptSsoSilentMsalLogin } from './msal/msal';
import { msalConfig } from './msal/msalConfig';
import Routes from './routes';
import './styles.css';
import FindALawyer from './views/FindALawyer/FindALawyer';
const PrivacyPolicy = lazy(() => import('./views/PrivacyPolicy/PrivacyPolicy'));
import { MantineProvider } from '@mantine/core';
import { theme } from './styles/mantine';
import { captureException } from './sentry/sentry';
import { Notifications } from '@mantine/notifications';
import GoogleVerification from './components/GoogleVerification';
import { ErrorView } from './components/ErrorView';
import { LoadingView } from './components/LoadingView';
const Auth = lazy(() => import('./views/Auth/Auth'));

const RedirectOldToNewURL = ({ match }: { match: any }) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const { letterId, matterId } = match.params;
  const [formattedName, setFormattedName] = useState<string | undefined>();

  useEffect(() => {
    authSession?.db
      .getFlattenedMatter(matterId)
      .then((doc) => {
        if (
          !doc?.contacts?.humans?.length ||
          doc.contacts.humans.length === 0
        ) {
          return;
        }
        const { firstName, lastName } = doc.contacts.humans[0];
        setFormattedName(
          `${encodeURIComponent(firstName ?? '')}_${encodeURIComponent(
            lastName ?? ''
          )}`
        );
      })
      .catch((error) => {
        console.error('Error fetching document:', error);
        captureException(error);
      });
  }, [authSession, matterId]);

  if (formattedName) {
    return (
      <Redirect to={`/engagement/${letterId}/${matterId}/${formattedName}`} />
    );
  }

  return null;
};

type ShadowEdge = 'top' | 'right' | 'bottom' | 'left';

function App() {
  const authSessionRef = useRef<AuthenticatedSession>();
  const [authSession, setAuthSession] = useState<
    AuthenticatedSession | undefined
  >(authSessionRef.current);
  const [authStateUnknown, setAuthStateUnknown] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setAuthStateUnknown(false);

      if (user && user.tenantId && user.email) {
        const pcaConfig = msalConfig(location);
        const msalApp = new PublicClientApplication(pcaConfig);
        authSessionRef.current = {
          sessionId: uuidv4(),
          tenantId: user.tenantId,
          userId: user.uid,
          userEmail: user.email,
          db: new FirmDatabase(user.tenantId, db),
          msalApp: new PublicClientApplication(pcaConfig),
        };
        setAuthSession(authSessionRef.current);
        logAuthSessionCreated(authSessionRef.current, pcaConfig);
        Sentry.setUser({
          email: user.email,
          id: user.uid,
          ip_address: '{{auto}}',
        });

        // This can take some time, so don't await it here.
        attemptSsoSilentMsalLogin(msalApp, user.tenantId, user.uid, user.email);
      } else {
        authSessionRef.current = undefined;
        setAuthSession(authSessionRef.current);
        logEvent(analytics, 'auth_session_missing');
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorView />}>
      <AuthenticatedSessionContext.Provider value={authSession}>
        <MantineProvider theme={theme}>
          <Notifications position='bottom-left' />
          <BrowserRouter>
            <FirebaseAnalytics />
            <Switch>
              <Route exact path='/'>
                {authSession ? <Redirect to='/dashboard' /> : <LandingPage />}
              </Route>
              <Route path='/findalawyer'>
                <FindALawyer />
              </Route>
              <Route path='/privacy'>
                <Suspense fallback={<LoadingView />}>
                  <PrivacyPolicy />
                </Suspense>
              </Route>
              <Route path='/login'>
                {authSession ? <Redirect to='/dashboard' /> : <Auth />}
              </Route>
              <Route>
                <div
                  className={`App ${
                    isSidebarCollapsed ? 'sidebar-collapsed' : ''
                  }`}
                >
                  <Switch>
                    <Route
                      path='/engagement/:letterId/:matterId/'
                      exact
                      render={({ match }) => (
                        <RedirectOldToNewURL match={match} />
                      )}
                    />
                    <Route
                      path='/engagement/:letterId/:matterId/:formattedName'
                      exact
                    >
                      <SignEngagementLetter />
                    </Route>

                    <Route
                      path='/engagement/:letterId/:matterId/:formattedName/:firmId'
                      exact
                    >
                      <SignEngagementLetter />
                    </Route>
                    <Route>
                      <Header openMobileMenu={() => {}} />
                      <SidebarMenu collapsed={isSidebarCollapsed} />
                      <MainAppContent
                        authStateUnknown={authStateUnknown}
                        shouldCollapseSidebar={(collapse) =>
                          setIsSidebarCollapsed(collapse)
                        }
                      />
                    </Route>
                  </Switch>
                </div>
              </Route>
              <Route path='/google9db2b2215cb7590d.html'>
                <GoogleVerification />
              </Route>
            </Switch>
          </BrowserRouter>
        </MantineProvider>
      </AuthenticatedSessionContext.Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;

const MainAppContent = ({
  authStateUnknown,
  shouldCollapseSidebar,
}: {
  authStateUnknown: boolean;
  shouldCollapseSidebar: (collapse: boolean) => void;
}) => {
  const [shadowEdges, setShadowEdges] = useState<ShadowEdge[]>([]);
  const location = useLocation();
  useEffect(() => {
    shouldCollapseSidebar(
      ['/spreadsheet', '/claims'].includes(location.pathname)
    );
  }, [location.pathname]);

  return (
    <main
      className={`main-content`}
      style={{
        borderTop: shadowEdges.includes('top')
          ? '1px solid rgba(0, 0, 0, 0.1)'
          : 'none',
        borderLeft: shadowEdges.includes('left')
          ? '1px solid rgba(0, 0, 0, 0.1)'
          : 'none',
      }}
      onScroll={(e) => {
        const newShadowEdges: ShadowEdge[] = [];
        if ((e.target as HTMLElement).scrollTop > 0) {
          newShadowEdges.push('top');
        }
        if ((e.target as HTMLElement).scrollLeft > 0) {
          newShadowEdges.push('left');
        }
        setShadowEdges(newShadowEdges);
      }}
    >
      {authStateUnknown ? <LoadingView /> : <Routes />}
    </main>
  );
};

const logAuthSessionCreated = (
  authSession: AuthenticatedSession,
  pcaConfig: Configuration
) => {
  logEvent(analytics, 'auth_session_created', {
    tenant_id: authSession.tenantId,
    user_id: authSession.userId,
    user_email: authSession.userEmail,
    client_id: pcaConfig.auth.clientId,
    authority: pcaConfig.auth.authority,
    redirect_uri: pcaConfig.auth.redirectUri,
  });
  console.info(
    `Successfully signed into tenant ${authSession.tenantId} as ${authSession.userEmail}`
  );
};
