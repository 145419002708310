import classes from './LandingGradientBackground.module.css';

const LandingGradientBackground = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className={classes.background}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default LandingGradientBackground;
